<script>
import _ from 'lodash'
import { getUuid, filterProp, removeProp } from '@/lib/utils'
import slotsMixin from '@/lib/mixins/slots'
const formRefName = `form${getUuid()}`

export default {
  name: 'mtsForm',
  mixins: [ slotsMixin ],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    config: {
      type: [Object, Array],
      required: true
    }
  },
  data () {
    return {
      formRefName,
      defaultFormProps: {
        labelPosition: 'right',
        labelWidth: '120px'
      },
      defaultRowProps: {},
      defaultFormValue: {}
    }
  },
  created () {},
  beforeDestroy () {},
  computed: {
    formConfig () {
      if(this.config instanceof Array) {
        return {
          items: this.config
        }
      } else {
        return this.config
      }
    },
    formData: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  render (h) {
    const children = []
    // const slots = _.merge({}, this.getScopedSlots())
    const formProps = _.merge(_.cloneDeep(this.defaultFormProps), _.cloneDeep(this.formConfig.props))
    const rowProps = _.merge(_.cloneDeep(this.defaultRowProps), _.cloneDeep(this.formConfig.rowProps))

    const formColumn = _.cloneDeep(this.formConfig.items)
    formColumn.forEach((formItem) => {
      if (['selection', 'actions', 'index', 'expand'].includes(formItem.type)) return
      if (formItem.hide || formItem.hideOnEdit) return
      const colProps = _.merge(_.cloneDeep(formItem.colProps), { span: formItem.span })
      const component = this.getFormComponent(formItem, h)
      const formItemProps = filterProp(formItem, ['label', 'label-width', 'required', 'rules', 'show-message', 'inline-message', 'size'])
      const formItemBox = <el-form-item props={{...formItemProps, prop: formItem.key}} class={formItem.class}>{component}</el-form-item>
      if (formProps.inline) {
        children.push(formItemBox)
      } else {
        children.push(<el-col props={colProps}>{formItemBox}</el-col>)
      }
    })

    if (formProps.inline) {
      return <el-form props={{...formProps, model: this.formData }} on={{input: this.formInputHandler}} ref={this.formRefName}>{children}</el-form>
    } else {
      return <el-form props={{...formProps, model: this.formData }} on={{input: this.formInputHandler}} ref={this.formRefName}><el-row props={rowProps}>{children}</el-row></el-form>
    }
  },
  methods: {
    formInputHandler () {
      console.log('input', arguments)
    },
    validate () {
      return this.g$formValidate(this.formRefName)
    },
    getComponentName (name) {
      const allowName = [
        'input', 'select', 'cascader', 'checkbox', 'radio', 'number', 'autocomplete',
        'switch', 'rate', 'slider', 'color', 'upload', 'tree', 'date', 'static',
        'email', 'file', 'password', 'range', 'search', 'tel', 'text', 'url',
        'input-number', 'time', 'datetime', 'checkbox-group', 'radio-group', 'color-picker',
        'image', 'auto-complete', 'time-select', 'datetimerange']
      if (!allowName.includes(name)) return 'el-input'
      switch (name) {
        case 'select': return ['el-select', 'el-option']
        case 'cascader': return 'el-cascader'
        case 'checkbox':
        case 'checkbox-group': return ['el-checkbox-group', 'el-checkbox']
        case 'radio':
        case 'radio-group': return ['el-radio-group', 'el-radio']
        case 'number':
        case 'input-number': return 'el-input-number'
        case 'auto-complete':
        case 'autocomplete': return 'el-autocomplete'
        case 'switch': return 'el-switch'
        case 'rate': return 'el-rate'
        case 'range':
        case 'slider': return 'el-slider'
        case 'color-picker':
        case 'color': return 'el-color-picker'
        case 'tree': return 'el-tree'
        case 'date':
        case 'datetimerange':
        case 'datetime': return 'el-date-picker'
        case 'time':
        case 'time-picker': return 'el-time-picker'
        case 'time-select': return 'el-time-select'
        case 'static': return 'span'
        case 'image':
        case 'upload': return 'el-upload'
        default: return 'el-input'
      }
    },
    getFormComponent (item) {
      let componentName = item.component
      let cfg = {}
      if (componentName instanceof Object) {
        cfg = _.cloneDeep(componentName)
        componentName = this.getComponentName(cfg.type)
      } else {
        cfg = { type: componentName }
        switch (componentName) {
          case 'action':
            cfg.type = 'slot'
            if(!cfg.slot) cfg.slot = 'formActions'
            break
          case 'slot':
            if(!cfg.slot) cfg.slot = item.key
            break
          default:
            componentName = this.getComponentName(componentName)
            break
        }
      }
      const slots = _.merge({}, this.getScopedSlots())
      const children = []
      if (cfg.slot && slots[cfg.slot]) {
        children.push(slots[cfg.slot]({ form: this.value }))
      } else {
        const props = removeProp(cfg, ['attrs', 'style', 'class'])
        let { attrs, style } = cfg
        if (props.placeholder) {
          if (!attrs) attrs = {}
          attrs.placeholder = props.placeholder
        }
        if (componentName instanceof Array && componentName.length === 2) {
          
          const optionName = componentName[1]
          const optionItems = []
          componentName = componentName[0]
          let items = item.enum || item.items
          if (!items && item.itemSlot && slots[item.itemSlot]) {
            optionItems.push(slots[item.itemSlot]({ form: this.value }))
          } else if (items instanceof Object) {
            let keys = Object.keys(items)
            let arr = []
            keys.forEach((v, idx) => {
              arr.push({ label: v, value: items[v], key: idx })
            })
            items = arr
          } else if (items instanceof Array) {
            items = items.map((v, idx) => {
              if (v instanceof Object) {
                const map = item.itemMap || { label: 'label', value: 'value' }
                return {
                  key: idx,
                  label: v[map.label],
                  value: v[map.value]
                }
              } else if (v instanceof Array) {
                const map = item.itemMap || { label: 0, value: 1 }
                return {
                  key: idx,
                  label: v[map.label],
                  value: v[map.value]
                }
              } else if (typeof v === 'string') {
                return {
                  key: idx,
                  label: v,
                  value: idx
                }
              }
            })
          }
          items.forEach((v) => {
            // console.log(v)
            if (optionName === 'el-option') optionItems.push(<optionName {...{ props: { label: v.label, key: v.key, value: v.value } }} />)
            else optionItems.push(<optionName {...{ props: { label: v.value, key: v.key } }}>{v.label}</optionName>)
          })
          children.push(<componentName {...{ props, style, attrs }} vModel={this.formData[item.key]}>{optionItems}</componentName>)
        } else {
          if (componentName === 'span') {
            children.push(<componentName {...{ props, style, attrs }}>{this.formData[item.key]}</componentName>)
          } else {
            // console.log(props)
            children.push(<componentName {...{ props, style, attrs }} vModel={this.formData[item.key]}></componentName>)
          }
        }
      }
      return children
    }
  }
}
</script>
