
export default {
  methods: {
    getSlots () {
      if (this.$slots) {
        return this.$slots
      } else {
        return {}
      }
    },
    getScopedSlots () {
      if (this.$scopedSlots) {
        return this.$scopedSlots
      } else {
        return {}
      }
    },
    findSlot (name, scope) {
      if (this.$scopedSlots && this.$scopedSlots[name]) {
        return this.$scopedSlots[name](scope)
      }
      return undefined
    }
  }
}