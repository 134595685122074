<template>
<div>
  <mts-table
    v-model="pageItems" :pager="{ 'current-page': current, 'page-size':size, total }"
    :loading.sync="dataListLoading" :config="tableConfig"
    @pager:size-change="sizeChanged"
    @pager:current-change="pageChanged">
    <template #tableHeader>
      <el-form class="searchForm" inline>
        <el-form-item>
          <el-input v-model="searchForm.keyWord" placeholder="请输入搜索关键词" clearable size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="searchDataList()" size="small" type="primary">搜索</el-button>
          <el-button size="small" @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button @click="showEditWin()" type="primary">添加超管</el-button>
    </template>
    <template #status="{ row }">
      <el-switch :value="row.status" :active-value="1" :inactive-value="0" @change="(status) => updateItemStatus(row, { status })"></el-switch>
    </template>
    <template #tenantList="{ row }">
      <span v-for="item in row.tenantList" :key="item.id" class="tenantListItem">{{item.name}}</span>
    </template>
    <template #tableActions="{ row }">
      <el-button type="text" @click.stop="showDetailWin(row)">详情</el-button>
      <el-button type="text" @click.stop="showEditWin(row)">编辑</el-button>
    </template>
  </mts-table>
  <el-dialog :title="dialogTitle" width="300"
    :visible="winVisible" @close="winVisible = false">
    <el-form label-position="right" label-width="120px"
      :model="form" :rules="editRules" ref="editForm">
      <el-form-item label="账号" prop="erpUserId">
        <el-input v-if="form.id" v-model="form.username" disabled
          placeholder="请先搜索账号"></el-input>
        <el-autocomplete v-else v-model="form.username"
          placeholder="输入手机号或用户名进行搜索"
          :fetch-suggestions="erpUserSuggestion"
          @select="handleUserSelect" class="fullWidth"
          ></el-autocomplete>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input v-model="form.realName" disabled
          placeholder="请先搜索账号"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="form.mobile" disabled
          placeholder="请先搜索账号"></el-input>
      </el-form-item>
      <el-form-item label="管理仓库" prop="tenantIdList">
        <el-select v-model="form.tenantIdList" multiple filterable remote
          placeholder="请用关键字搜索仓库" :remote-method="tenantSuggestion" class="fullWidth">
          <el-option v-for="item in tenantItems" :key="item.id" :value="item.id" :label="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" v-model="form.remark" :rows="2"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="tenantAdminFooter">
      <div class="left">
        <el-button v-if="!form.id" type="text" @click="openRegisterForm">未注册？去创建账号</el-button>
      </div>
      <div class="right">
        <el-button @click="winVisible = false">取消</el-button>
        <el-button type="primary" @click="submitHandle()">确认</el-button>
      </div>
    </div>
  </el-dialog>
  <el-dialog title="创建超管账号" width="300"
    :visible="registerWinVisible" @close="registerWinVisible = false">
    <mts-form v-model="registerForm" :config="registerConfig" ref="registerForm" class="registerFormWin">
      <template #tenantIdList="{ form }">
        <el-select v-model="form.tenantIdList" multiple filterable remote
          placeholder="请用关键字搜索仓库" :remote-method="tenantSuggestion" class="fullWidth">
          <el-option v-for="item in tenantItems" :key="item.id" :value="item.id" :label="item.label"></el-option>
        </el-select>
      </template>
    </mts-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="registerWinVisible = false">取消</el-button>
      <el-button type="primary" @click="registerHandle()">确认</el-button>
    </span>
  </el-dialog>
  <el-dialog
    title="查看超管账号资料" width="300"
    :visible="detailWinVisible"
    @close="detailWinVisible = false">
    <mts-form v-model="form" :config="detailConfig" ref="detailForm" class="viewFormWin">
      <template #status="{ form }">{{ form.status === 1 ? '正常' : '停用' }}</template>
      <template #tenantList="{ form }">
        <ul class="tenantListDetailItems">
          <li v-for="item in form.tenantList" :key="item.id">{{item.code}} {{item.name}}({{item.typeName}})</li>
        </ul>
      </template>
    </mts-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="detailWinVisible = false">关闭</el-button>
    </span>
  </el-dialog>
</div>
</template>
<script>
import { tableItems, registerItems, detailItems } from './tenantAdmin.config'
import { mapState, mapActions } from 'vuex'
import mtsTable from '@/components/mts/table'
import mtsForm from '@/components/mts/form'
import api from '@/lib/api'
import _ from 'lodash'

export default {
  data () {
    return {
      tableConfig: {
        tableProps: {
          border: true
        },
        items: tableItems
      },
      searchForm: {
        keyWord: ''
      },
      dataListLoading: false,
      winVisible: false,
      detailWinVisible: false,
      form: {
        erpUserId: null,
        tenantIdList: [],
        username: '',
        realName: '',
        mobile: '',
        remark: ''
      },
      editRules: {
        erpUserId: [
          { required: true, message: '请选择一个账号', trigger: 'change' }
        ],
        // tenantIdList: [
        //   { required: true, message: '请至少选择一个仓库', trigger: 'change' }
        // ]
      },
      registerWinVisible: false,
      registerForm: {
        tenantIdList: [],
        username: '',
        realName: '',
        mobile: '',
        remark: '',
        password: '',
        confirmPassword: ''
      },
    }
  },
  components: {
    mtsTable , mtsForm
  },
  activated () {
    this.getDataList()
  },
  computed: {
    ...mapState('tenantAdmin', ['current', 'size', 'total', 'pageItems', 'tenantItems']),
    registerConfig () {
      return {
        items: registerItems,
        props: {
          labelWidth: '120px'
        }
      }
    },
    detailConfig () {
      let items = _.cloneDeep(detailItems)
      items.forEach((v) => {
        if (v.component !== 'slot') {
          v.component = 'static'
        }
      })
      return {
        items,
        props: {
          labelWidth: '120px'
        }
      }
    },
    dialogTitle () {
      if (this.form && this.form.id) {
        return '修改超管账号'
      } else {
        return '添加超管账号'
      }
    }
  },
  methods: {
    ...mapActions('tenantAdmin', [ 'page', 'save', 'modify', 'detail', 'updateStatus', 'tenantSearch', 'insertTenantItem' ]),
    sizeChanged (size) {
      this.getDataList(1, size)
    },
    pageChanged (page) {
      this.getDataList(page)
    },
    searchDataList () {
      this.getDataList(1)
    },
    resetSearch () {
      let form = this.$options.data().searchForm
      this.$set(this, 'searchForm', form)
      this.$nextTick(() => {
        this.getDataList(1)
      })
    },
    getDataList (p, s) {
      if (!p) p = this.current
      if (!s) s = this.size
      this.dataListLoading = true
      let params = {
        page: p,
        limit: s,
        ...this.searchForm
      }
      return this.page(params).then(() => {
        this.dataListLoading = false
      }).catch((e) => {
        this.dataListLoading = false
        this.$message.error(e.message)
      })
    },
    updateItemStatus (row, data) {
      this.$confirm('确认修改此项的状态？', '修改超管状态', {
        confirmButtonText: '提交',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let form = _.cloneDeep(data)
        form.id = row.id
        this.updateStatus(form).then(() => {
          this.getDataList()
          this.$message.success('修改超管状态成功')
        }).catch((e) => {
          console.error(e)
          this.$message.error(e.message)
        })
      })
    },
    // 新增 / 修改
    showEditWin (row) {
      let form = {}
      if (row && row.id) {
        this.detail(row.id).then((json) => {
          form = _.cloneDeep(json.data)
          form.tenantIdList = form.tenantList.map((v) => v.id)
          form.erpUserId = form.id
          this.insertTenantItem(form.tenantList)
          this.$set(this, 'form', form)
          this.tenantSuggestion('')
          this.winVisible = true
        })
      } else {
        let { form } = this.$options.data()
        this.$set(this, 'form', form)
        this.tenantSuggestion('')
        this.winVisible = true
      }
    },
    openRegisterForm () {
      let form = this.$options.data().registerForm
      this.$set(this, 'registerForm', form)
      this.tenantSuggestion('')
      this.$nextTick(() => {
        this.winVisible = false
        this.registerWinVisible = true
      })
      
    },
    tenantSuggestion (keyWord) {
      let params = {
        keyWord,
        limit: 100,
        page: 1
      }
      this.tenantSearch(params)
    },
    erpUserSuggestion (keyword, next) {
      let params = {
        keyword,
        limit: 100,
        page: 1
      }
      api.erpUser.page(params).then((res) => {
        let { code, msg, data } = res
        console.log(code, msg, data)
        if (code === 0 && data) {
          let items = data.records.map((v) => {
            return {
              value: `${v.username}(${v.realName})${v.mobile}`,
              ...v
            }
          })
          next(items)
        } else {
          this.$message.error(msg)
        }
      })
    },
    handleUserSelect (item) {
      if (typeof item === 'string') return
      this.$set(this.form, 'erpUserId', item.id)
      this.$set(this.form, 'username', item.username)
      this.$set(this.form, 'mobile', item.mobile)
      this.$set(this.form, 'realName', item.realName)
    },
    showDetailWin (row) {
      if (!row || !row.id) return
      this.detail(row.id).then((json) => {
        let form = _.cloneDeep(json.data)
        this.$set(this, 'form', form)
        this.detailWinVisible = true
      })
    },
    registerHandle () {
      let form = _.cloneDeep(this.registerForm)
      if (form.password !== form.confirmPassword) {
        this.$alert('两次输入的密码不一致', '创建超管账号', { type: 'error' })
        return
      }
      this.$refs.registerForm.validate().then(() => this.save(form)).then(() => {
        this.$message.success('创建超管账号成功')
        this.getDataList(1)
        this.registerWinVisible = false
      }).catch((e) => {
        this.$alert(e.message, '创建超管账号', { type: 'error' })
      })
    },
    submitHandle () {
      let tenantIdList = _.cloneDeep(this.form.tenantIdList)
      let form = {
        remark: this.form.remark,
        erpUserId: this.form.erpUserId,
        tenantIdList
      }
      this.$refs.editForm.validate((valid, error) => {
        if (!valid) {
          console.log(error)
          let msgs = []
          Object.keys(error).forEach(k => {
            let ret = error[k].map(v => v.message)
            msgs = msgs.concat(ret)
          })
          this.$alert(msgs[0], this.dialogTitle, { type: 'error' })
          return
        }
        this.modify(form).then(() => {
          this.$message.success(this.dialogTitle + '成功')
          this.getDataList(1)
          this.winVisible = false
        }).catch((e) => {
          this.$alert(e.message, this.dialogTitle, { type: 'error' })
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tenantListItem {
  & + .tenantListItem::before {
    content: "、";
  }
}
.tenantAdminFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    text-align: left;
  }
}
.tenantListDetailItems {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    display: inline-block;
    & + li {
      margin-left: 1em;
    }
  }
}
</style>