import api from '@/lib/api/tenantAdmin'

export const tableItems = [
  {
    key: 'id',
    label: '超管ID',
    hide: true,
    primary: true
  }, {
    key: 'username',
    label: '用户名'
  }, {
    key: 'realName',
    label: '姓名'
  }, {
    key: 'mobile',
    label: '手机号'
  }, {
    key: 'tenantList',
    label: '授权仓库',
    width: 160,
    slot: true,
  }, {
    key: 'status',
    label: '状态',
    width: 100,
    slot: true,
    hideOnEdit: true
  }, {
    key: 'createDate',
    label: '创建时间',
    width: 160,
    hideOnEdit: true,
    rules: []
  }, {
    type: 'actions',
    label: '操作',
    width: 120,
    fixed: 'right'
  }
]

export const registerItems = [
  {
    key: 'username',
    label: '用户名',
    component: 'input',
    rules: [
      { required: true, message: '请填写用户名', trigger: 'blur' },
      { pattern: /^\S{2,60}$/, message: '请正确填写用户名', trigger: 'blur' },
      { validator (rule, value, callback) {
        api.checkUsername(value).then((data) => {
          if (data.code !== 0) {
            callback(new Error(data.msg))
          } else {
            callback()
          }
        })
      }, trigger: 'blur'}
    ]
  }, {
    key: 'password',
    label: '登录密码',
    component: 'password',
    rules: [
      { required: true, message: '请填写登录密码', trigger: 'blur' },
      { pattern: /^\S{6,16}$/, message: '请正确填写登录密码', trigger: 'blur' }
    ]
  }, {
    key: 'confirmPassword',
    label: '确认密码',
    component: 'password',
    rules: [
      { required: true, message: '请填写确认密码', trigger: 'blur' },
      { pattern: /^\S{6,16}$/, message: '请正确填写确认密码', trigger: 'blur' }
    ]
  }, {
    key: 'realName',
    label: '姓名',
    component: 'input',
    rules: [
      { required: true, message: '请填写姓名', trigger: 'blur' },
      { pattern: /^\S{2,32}$/, message: '请正确填写姓名', trigger: 'blur' }
    ]
  }, {
    key: 'mobile',
    label: '手机号码',
    component: 'input',
    rules: [
      { required: true, message: '请填写手机号码', trigger: 'blur' },
      { pattern: /^1[3-9][0-9]{9}$/, message: '请正确填写手机号码', trigger: 'blur' },
      { validator (rule, value, callback) {
        api.checkMobile(value).then((data) => {
          if (data.code !== 0) {
            callback(new Error(data.msg))
          } else {
            callback()
          }
        })
      }, trigger: 'blur'}
    ]
  }, {
    key: 'tenantIdList',
    label: '管理仓库',
    component: 'slot',
    // rules: [
    //   { required: true, message: '请至少选择一个仓库', trigger: 'change' }
    // ]
  }, {
    key: 'remark',
    label: '备注',
    component: {
      type: 'textarea',
      rows: 2,
      placeholder: '请输入备注'
    }
  }
]

export const detailItems = [
  {
    key: 'username',
    label: '用户名'
  }, {
    key: 'realName',
    label: '姓名'
  }, {
    key: 'mobile',
    label: '手机号码'
  }, {
    key: 'status',
    label: '状态',
    component: 'slot'
  }, {
    key: 'tenantList',
    label: '管理仓库',
    component: 'slot'
  }, {
    key: 'remark',
    label: '备注',
    component: {
      type: 'textarea',
      rows: 2,
      placeholder: '请输入备注'
    }
  }
]