<script>
import _ from 'lodash'
import { getUuid } from '@/lib/utils'
import slotsMixin from '@/lib/mixins/slots'
const tableRefName = `table${getUuid()}`
const pagerRefName = `pager${getUuid()}`
export default {
  name: 'mtsTable',
  mixins: [ slotsMixin ],
  props: {
    value: {
      type: Array,
      required: true
    },
    config: {
      type: [Object, Array],
      required: true
    },
    pager: {
      type: [Object, Boolean],
      defalut: false
    },
    loading: {
      type: Boolean,
      defalut: false
    }
  },
  data() {
    return {
      tableRefName,
      pagerRefName,
      tableHeight: null,
      defaultTableProps: {},
      defaultTableColumnProps: {
        'header-align': 'center',
        'align': 'center'
      },
      defaultPagerProps: {},
      tableListeners: {},
      pagerListeners: {}
    }
  },
  created () {
    const keys = Object.keys(this.$listeners)
    const pagerKey = [...keys].filter((n) => n.startsWith('pager:'))
    if (pagerKey) {
      pagerKey.forEach((v) => {
        this.pagerListeners[v.substring(6)] = this.$listeners[v]
      })
    }
    if (keys) {
      keys.forEach((v) => {
        if (pagerKey.includes(v)) return
        else this.tableListeners[v] = this.$listeners[v]
      })
    }
    // this.$on('update:loading', (val) => { this.$set(this, 'loading', val) })
    // this.$on('change', (val) => { this.$set(this, 'value', val) })
  },
  activated () {
    let $table = this.$refs[this.tableRefName].$el
    this.$set(this, 'tableHeight', $table.clientHeight)
    this.doLayout()
  },
  computed: {
    tableConfig () {
      if (this.config instanceof Array) {
        return {
          items: this.config
        }
      } else {
        return this.config
      }
    }
  },
  render (h) {
    const tableColumns = this.generateTableColumn(h)
    const tableProps = _.merge(_.cloneDeep(this.defaultTableProps), _.cloneDeep(this.tableConfig.tableProps))
    const tableAttrs = _.cloneDeep(this.tableConfig.tableAttrs)
    const ret = []
    
    const hss = this.findSlot('tableHeader', { table: this })
    // console.log(hss)
    if (hss) {
      ret.push(h('div', { class: 'mts-table__header' }, hss))
    }
    // console.log(this.$listeners)
    const children = [<el-table ref={this.tableRefName}
      props={tableProps} data={this.value} height={this.tableHeight}
      on={this.tableListeners} attrs={tableAttrs}
      vLoading={this.loading}>{tableColumns}</el-table>]
    ret.push(<div class="mts-table__table">{children}</div>)

    if (this.pager) {
      const pagerProps = _.merge(_.cloneDeep(this.defaultPagerProps), _.cloneDeep(this.tableConfig.pagerProps))
      if (this.pager !== true && this.pager instanceof Object) {
        Object.keys(this.pager).forEach((key) => {
          pagerProps[key] = this.pager[key]
        })
      }
      let pager = [<el-pagination ref={this.pagerRefName} props={pagerProps} on={this.pagerListeners}/>]
      const fss = this.findSlot('tableFooter', { table: this })
      // console.log(fss)
      if (fss) {
        pager.push(h('div', { class: 'mts-table__footerLeft' }, fss))
      }
      ret.push(<div class="mts-table__footer">{pager}</div>)
    }

    return <el-card class="mts-table">{ret}</el-card>
  },
  methods: {
    doLayout () {
      this.$nextTick(() => {
        this.$refs[this.tableRefName].doLayout()
      })
    },
    generateTableColumn (h) {
      const slots = _.merge({}, this.getScopedSlots())
      const tableColumn = _.cloneDeep(this.tableConfig.items)
      const children = []
      tableColumn.forEach((colProps) => {
        let col = _.merge(_.cloneDeep(this.defaultTableColumnProps), _.cloneDeep(colProps))
        if (col.hide || col.hideOnView || col.hideOnTableView) return
        if (col.type === 'actions') {
          delete col.type
          if (!col.label) col.label = '操作'
          if (!col.slot) col.slot = 'tableActions'
        }
        if (colProps.scopedSlots) {
          let scopedSlots = colProps.scopedSlots
          delete col.scopedSlots
          const ss = { default: (row) => scopedSlots(h, row) }
          children.push(<el-table-column props={col} scopedSlots={ss}/>)
          return
        } else if (col.slot) {
          let slotName = col.slot === true ? col.key : col.slot
          if (slots[slotName]) {
            delete col.slot
            const ss = { default: (row) => slots[slotName](row) }
            children.push(<el-table-column props={col} scopedSlots={ss}/>)
            return
          }
        }
        if (!col.prop) col.prop = col.key
        if (!col['column-key']) col['column-key'] = col.key
        if (!col.label) col.label = col.key
        children.push(<el-table-column props={col} />)
      })
      return children
    }
  }
}
</script>
